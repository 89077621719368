.full-screen {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: auto;
  animation: slide-up 0.3s ease-out;

  &__heading {
    margin: 1rem 0 2rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e4e7e6;
    color: var(--clr-primary);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-md);
    font-size: 1.5rem;

    &__close-btn {
      display: flex;
      margin-left: auto;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      background-color: var(--clr-lightgray);
      border: 0;
      border-radius: 1rem;
      padding: 1rem;
    }
  }

  &__content {
    flex: 1;
    overflow-y: auto;
  }

  &.partial {
    height: 70%;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }

  &.full {
    height: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.closing {
    animation: slide-down 0.3s ease-out;
  }
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: color-mix(in srgb, var(--clr-black) 50%, transparent 50%);
  backdrop-filter: blur(2px);
  z-index: 10000;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}