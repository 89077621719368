@import "../../_variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 1rem;
  text-align: center;
}

.header {
  color: var(--clr-black);
  font-weight: var(--fw-400);
  margin-bottom: 0.8rem;

  .checkIcon {
    font-size: var(--fs-xs);
  }
}

.heading{
  margin-bottom: 1rem;
  font-size: var(--fs-xs);
}

.subheader {
  color: var(--clr-black);
  font-size: var(--fs-xs);
  font-weight: var(--fw-400);
  line-height: 24px;

  span {
  font-size: var(--fs-xs);
  font-weight: var(--fw-400);
  line-height: 24px;
  color: var(--clr-primary);
}
}
