@import "../../_variables";

.rbc-calendar {
  padding: var(--spacing-md);
  height: 100%;
  min-height: 80rem;
  max-height: 100vh;
  border: 1px solid var(--bd-clr-primary);
  border-radius: var(--form-section-br);

  @media screen and (max-width: $breakpoint-xs) {
    padding: 0;
    border: 0;
    border-radius: 0;
  }

  .rbc-time-view-resources .rbc-header,
  .rbc-time-view-resources .rbc-day-bg,
  .rbc-time-view-resources .rbc-day-slot {
    min-width: 25rem;
  }

  .rbc-time-view-resources .rbc-time-header-gutter {
    border-color: var(--bd-clr-primary);
  }

  .rbc-time-view-resources .rbc-time-gutter,
  .rbc-time-view-resources .rbc-time-header-gutter,
  .rbc-time-header-content > .rbc-row.rbc-row-resource,
  .rbc-time-content > * + * > * {
    border: 0;
  }

  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid var(--bd-clr-primary);
  }

  .rbc-time-view-resources .rbc-time-header-gutter {
    border-right: 1px solid var(--bd-clr-primary);
  }

  // .rbc-day-slot .rbc-event,
  // .rbc-day-slot .rbc-background-event {
  //   // border: 0;
  // }

  .rbc-toolbar {
    flex-direction: row;
    justify-content: center;
    margin-bottom: var(--spacing-md);

    @media screen and (max-width: $breakpoint-xs) {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      flex-wrap: nowrap;
    }

    &-date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      margin-right: auto;

      &-today {
        &-button {
          // position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          left: 0;
          padding-left: 0.7rem 1.4rem;
          width: 100%;
          height: 100%;
          font-size: 1.2rem;
          font-weight: var(--fw-400);
          line-height: 1.5;
          color: var(--clr-primary);
          background-color: var(--clr-white);
          border: 1px solid var(--bd-clr-primary);
          border-radius: 0.7rem;
          cursor: pointer;
        }
      }

      &-picker {
        &-icon {
          position: absolute;
          top: 50%;
          right: 1.4rem;
          transform: translateY(-50%);
          z-index: 600;
          pointer-events: none;
        }

        &-input {
          padding: 0.7rem 1.4rem;
          color: transparent;
          border: 0;

          @media screen and (max-width: $breakpoint-xs) {
            padding: 0.7rem 0.5rem;
          }
        }

        &-placeholder {
          position: absolute;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          top: 0;
          left: 0;
          padding-left: 1.4rem;
          padding-right: 2.5rem;
          width: 100%;
          height: 100%;
          font-size: 1.2rem;
          font-weight: var(--fw-400);
          line-height: 1.5;
          color: var(--text-clr);
          background-color: var(--clr-white);
          border: 1px solid var(--bd-clr-primary);
          border-radius: 0.7rem;
          z-index: 500;
          pointer-events: none;
        }
      }
    }

    &-label {
      flex-grow: 0;
      font-size: 2.2rem;
      font-weight: var(--fw-400);
      line-height: 1.5;
      color: var(--text-clr);
      padding: 0;

      @media screen and (max-width: $breakpoint-xs) {
        font-size: 1rem;
      }
    }

    &-button {
      width: 3.5rem;
      height: 3.5rem;
      color: transparent !important;
      background-color: transparent !important;
      border: 0 !important;
      box-shadow: none !important;
      cursor: pointer;
      user-select: none;

      &::before {
        content: url("../../assets/icons/arrow.svg");
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center;
      }

      &--next {
        &::before {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }
  }

  .rbc-row-resource {
    .rbc-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.3rem;
      padding: 1rem;
      border: 0;

      &-title {
        font-size: 1.4rem;
        font-weight: var(--fw-500);
        line-height: 1.5;
        color: var(--text-clr);
      }

      &-subtitle {
        font-size: 1.2rem;
        font-weight: var(--fw-400);
        line-height: 1.5;
        color: var(--text-clr);

        img {
          top: 3px;
          margin-right: 0.5rem;
        }
      }
    }
  }

  .rbc-month-header {
    .rbc-header {
      padding: 1.2rem 0;
      border: 0;
      border-bottom: 1px solid var(--clr-lightgray);

      span {
        font-size: 1.4rem;
        font-weight: var(--fw-400);
        line-height: 1.5;
        color: color-mix(in srgb, var(--text-clr) 50%, var(--clr-white) 50%);
      }
    }
  }

  .rbc-time-header-cell {
    .rbc-header {
      padding: 1rem;
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
      border: 0;

      &.rbc-today {
        background-color: var(--clr-primary);

        .rbc-button-link {
          span,
          & span:first-child {
            font-weight: var(--fw-700);
            color: var(--clr-white);
          }
        }
      }

      .rbc-button-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        width: 100%;

        span {
          font-size: 1.4rem;
          font-weight: var(--fw-400);
          line-height: 1.5;
          color: var(--text-clr);

          &:first-child {
            color: color-mix(
              in srgb,
              var(--text-clr) 50%,
              var(--clr-white) 50%
            );
          }
        }
      }
    }
  }

  .rbc-time-content {
    border-top: 1px solid var(--bd-clr-primary);
  }

  .rbc-time-slot {
    display: flex;
    justify-content: center;
    align-items: center;

    .rbc-label {
      font-size: 1.2rem;
      font-weight: var(--fw-400);
      line-height: 1.5;
      color: color-mix(in srgb, var(--text-clr) 50%, var(--clr-white) 50%);
    }
  }

  .rbc-timeslot-group {
    padding: 0 1.5rem;
    border: 1px dashed var(--bd-clr-primary);

    &:first-of-type {
      border-top: 0;
    }

    &:last-of-type {
      border-bottom: 0;
    }
  }

  .rbc-month-row {
    & + .rbc-month-row,
    .rbc-day-bg {
      border-color: var(--clr-lightgray);
    }
  }

  .rbc-event {
    padding: 0;
    background-color: transparent !important;
    border: 0;

    &-label {
      display: none;
    }

    &-wrapper {
      padding: 0.5rem;
      width: 100%;
      height: 100%;
      // background-color: color-mix(
      //   in srgb,
      //   royalblue 50%,
      //   var(--clr-white) 100%
      // );
    }
  }

  .rbc-month-view {
    border-color: var(--clr-lightgray);
  }

  .rbc-monthly-event {
    &__content {
      display: flex;
      align-items: center;
      padding: 1rem 1.5rem;
      background-color: var(--clr-lightgray);
      border-top: 5px solid red;
      border-radius: 0.5rem;
      overflow-x: auto;
      white-space: nowrap;

      &__inner {
        display: grid;
        grid-template-areas:
          "duration actions"
          "title title"
          "date info";
        gap: 0.5rem;
        align-items: center;
        width: 100%;
      }

      &__title {
        grid-area: title;
        font-size: 1.2rem;
        line-height: 1.5;
        color: var(--text-clr);
      }

      &__duration,
      &__date {
        line-height: 1.5;
        color: color-mix(in srgb, var(--text-clr) 50%, var(--clr-white) 50%);
      }

      &__duration {
        grid-area: duration;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;

        &::before {
          content: url("../../assets/icons/clock.svg");
        }
      }

      &__date {
        grid-area: date;
      }

      &__actions {
        grid-area: actions;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;

        &__button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.2rem;
          height: 2.2rem;
          background-color: transparent;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 0.6rem;
          cursor: pointer;
        }

        &__image{
          height:1.2rem;
          width:1.2rem;
        }
      }

      &__info {
        grid-area: info;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;

        &__priority {
          place-self: end;
          display: block;
          padding: 0.5rem;
          width: fit-content;
          color: var(--clr-white);
          font-size: 0.7rem;
          font-weight: var(--fw-600);
          line-height: 1.5;
          text-align: center;
          background-color: #4169e1;
          border-radius: 1rem;
        }

        &__assignee {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2rem;
          height: 2rem;
          font-size: 0.8rem;
          font-weight: var(--fw-600);
          line-height: 1.5;
          text-transform: uppercase;
          color: var(--clr-white);
          background-color: var(--clr-primary);
          border-radius: 50%;
        }
      }
    }
  }

  .rbc-weekly-event {
    &__content {
      display: flex;
      align-items: center;
      padding: 1rem 1rem;
      height: 100%;
      background-color: var(--clr-lightgray);
      border-top: 5px solid red;
      border-radius: 0.5rem;
      overflow: hidden;

      &__inner {
        display: grid;
        grid-template-areas:
          "duration actions"
          "title title"
          "date info";
        gap: 0.5rem;
        align-items: center;
        width: 100%;
      }

      &__title {
        grid-area: title;
        font-size: 1.2rem;
        font-weight: var(--fw-600);
        line-height: 1.5;
        color: var(--text-clr);
      }

      &__duration,
      &__date {
        font-size: 1rem;
        font-weight: var(--fw-400);
        line-height: 1.5;
        color: color-mix(in srgb, var(--text-clr) 50%, var(--clr-white) 50%);
      }

      &__duration {
        grid-area: duration;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;

        &::before {
          content: url("../../assets/icons/clock.svg");
        }
      }

      &__date {
        grid-area: date;
      }

      &__actions {
        grid-area: actions;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;

        &__button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.2rem;
          height: 2.2rem;
          background-color: transparent;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 0.6rem;
          cursor: pointer;
        }

        &__image{
          height: 2rem;
          width:2rem;
        }
      }

      &__info {
        grid-area: info;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.7rem;

        &__priority {
          place-self: end;
          display: block;
          padding: 0.5rem;
          color: var(--clr-white);
          width: 100%;
          max-width: 4.4rem;
          font-size: 0.7rem;
          font-weight: var(--fw-600);
          line-height: 1.5;
          text-align: center;
          background-color: #4169e1;
          border-radius: 1rem;
        }

        &__assignee {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2rem;
          height: 2rem;
          font-size: 0.8rem;
          font-weight: var(--fw-600);
          line-height: 1.5;
          text-transform: uppercase;
          color: var(--clr-white);
          background-color: var(--clr-primary);
          border-radius: 50%;
        }
      }
    }
  }

  .rbc-allday-cell {
    display: none !important;
  }

  .rbc-time-header-content,
  .rbc-time-header.rbc-overflowing {
    border: 0;
  }

  .rbc-date-cell {
    padding: 0.5rem;
    color: color-mix(in srgb, var(--text-clr) 50%, var(--clr-white) 50%);
  }

  .rbc-today {
    background-color: transparent;
    // background: color-mix(
    //   in srgb,
    //   var(--clr-yellow) 10%,
    //   var(--clr-white) 100%
    // );
  }

  .rbc-agenda {
    &-date-cell {
      display: none;
    }

    &-time-cell {
      display: none;
    }
  }

  .rbc-agenda-table {
    thead {
      display: none;
    }
  }

  .rbc-off-range-bg {
    background: color-mix(
      in srgb,
      var(--clr-lightgray) 50%,
      var(--clr-white) 50%
    );
  }
}

.agenda-event {
  display: flex;
  align-items: center;
  background-color: var(--clr-lightgray);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  position: relative;

  &__bar {
    display: block;
    width: 0.4rem;
    height: 7rem;
    border-radius: 0.5rem;
  }

  &__time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 6rem;
    font-size: 1.2rem;
    font-weight: var(--fw-400);
    color: var(--text-clr);

    img {
      width: 1rem;
      height: 1rem;
      margin-bottom: 0.3rem;
    }

    span:first-child {
      font-size: 1.4rem;
      font-weight: var(--fw-600);
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;

    &__header {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      &__date {
        font-size: 1.2rem;
        color: var(--text-clr-light);

        @media screen and (max-width: $breakpoint-xs) {
          font-size: 1rem;
        }
      }

      &__title {
        @media screen and (max-width: $breakpoint-xs) {
          font-size: 1.2rem;
        }
      }

      &__details {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        &__priority {
          padding: 0.4rem 1rem;
          border-radius: 1rem;
          color: white;
        }

        &__assignee {
          background-color: #427662;
          padding: 0.4rem;
          color: var(--clr-white);
          border-radius: 50%;
        }
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.2rem;
      height: 2.2rem;
      background-color: transparent;
      border: 1px solid var(--clr-lightgray);
      border-radius: 0.5rem;
      cursor: pointer;

      img {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }
}
