@import "../_variables";

.task-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.25rem;
  list-style-type: none;
  width: 100%;

  &--without-statusbar {
    .task-list__item {
      background-color: var(--clr-white);

      &::after {
        content: "";
        display: block;
        width: 4rem;
        height: 0.25rem;
        background-color: var(--clr-lightgray);
        position: absolute;
        bottom: -0.125rem;
        left: 2.5rem;
        transform: translateX(-50%);
        border-radius: 1rem;
      }
    }

    .task-list__info {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-xs);
      font-size: 1.2rem;

      &::before {
        content: url("../assets/icons/clock.svg");
      }
    }

    .task-list__heading {
      gap: var(--spacing-xs);
    }
  }

  &__item {
    display: flex;
    justify-content: stretch;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
    background-color: color-mix(
      in srgb,
      var(--clr-lightgray) 50%,
      var(--clr-white) 50%
    );
    border-radius: 0.7rem;
    transition: background-color 0.3s ease, opacity 1s ease;
    position: relative;
    margin-bottom: 0.2rem;

    &--IN_PROGRESS {
      background-color: var(--clr-lightgreen);
    }

    &--COMPLETED {
      opacity: 0.5;
      background-color: var(--clr-lightgray);
    }

    &--OVERDUE {
      opacity: 0.5;
      background-color: var(--clr-lightgray);
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 1.15rem;
    flex: 0 0 auto;
    font-size: var(--fs-xxs);

    &__month {
      opacity: 50%;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.2rem;
    padding: 1.5rem;
    border-radius: 1rem;

    &--inProgress {
      background-color: color-mix(
        in srgb,
        var(--clr-primary) 20%,
        var(--clr-white) 80%
      );
    }

    &--overdue {
      background-color: color-mix(
        in srgb,
        var(--clr-yellow) 20%,
        var(--clr-white) 80%
      );
    }

    &--completed {
      opacity: 0.6;
      background-color: var(--clr-lightgray);
      color: var(--clr-lightgray);
      pointer-events: none;
    }

    &--canceled {
      background-color: #fff0f0;
    }

    &--drafted {
      background-color: #f2f3f2;
    }
  }

  &__checkbox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: 2.4rem;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 1;

      &:disabled {
        cursor: default;
      }

      & + svg {
        rect {
          fill: var(--clr-white);
          stroke: var(--bd-clr-primary);
        }

        path {
          display: none;
          stroke: var(--clr-primary);
        }
      }

      &:checked {
        & + svg {
          rect {
            fill: var(--clr-lightgreen);
          }
          path {
            display: block;
          }
        }
      }
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1;

    &__title {
      font-size: var(--fs-xxs);
      font-weight: var(--fw-500);
      line-height: 1.5;
      color: var(--text-clr);
    }

    &__subtitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.5;
      font-weight: var(--fw-400);
      color: var(--text-clr);

      img {
        margin-right: 0.5rem;
      }
    }
  }

  &__info-wrapper {
    // flex: 0 0 10rem;
    width: 5rem;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto;

    span {
      font-size: var(--fs-xxs);
      font-weight: var(--fw-400);
      line-height: 1.5;
      // white-space: nowrap;
      color: #7b8794;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;
    background-color: transparent;
    border-color: color-mix(in srgb, var(--clr-black) 10%, transparent 90%);
    border-radius: 1rem;
    cursor: pointer;
  }

  &__status {
    width: 0.4rem;
    height: 4.5rem;
    margin-right: 0.5rem;
    border-radius: 1rem;
    background-color: var(--clr-primary);

    &--COMPLETED {
      background-color: var(--clr-completed);
    }

    &--IN_PROGRESS {
      background-color: var(--clr-in-progress);
    }

    &--OVERDUE {
      background-color: var(--clr-overdue);
    }

    &--upcoming {
      background-color: var(--clr-blue);
    }
  }
}

.success-modal-content {
  text-align: center;

  &__title {
    font-size: 2.2rem;
    font-weight: 500;
    height: 2.9rem;
    margin: 2rem;
  }
  &__description {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  button {
    width: 25rem;
    margin:2rem auto;
  }
}
