.error-container {
  background-color: #fff5f5;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-bottom: 1rem;
  grid-column: span 2;
  margin-bottom: 2rem;

  &__error-message {
    color: #ff4d4d;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
  }
}

.input-error {
  border: 2px solid #db2d16 !important;
}

.success-modal-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-sm);
    text-align: center;

    img{
        width: 5rem;
        height: 5rem;
    }

    p{
        font-size: 1.3rem;
    }

    span{
        color: var(--clr-primary);
    }
}
