@import "../_variables";

.setup-fields {
  display: flex;
  flex-direction: column;
  background-color: var(--clr-white);
  padding: 2rem;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
  height: 100vh;
  overflow: auto;

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2rem;
  }

  &__selectAll {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }

  &__selectAllCheckbox {
    position: absolute;
    opacity: 0;
    z-index: 1;
    left: 0;
    width: 4rem;
    height: 2rem;

    & + svg {
      height: fit-content;

      rect {
        fill: var(--clr-white);
        stroke: var(--bd-clr-primary);
      }

      path {
        display: none;
        stroke: var(--clr-primary);
      }
    }

    &:checked {
      & + svg {
        rect {
          fill: var(--clr-lightgreen);
        }
        path {
          display: block;
        }
      }
    }
  }

  &__selectAllText {
    text-transform: uppercase;
    color: rgba(153, 153, 153, 1);
    padding-left: 1.1rem;
    font-weight: 500;
    font-size: var(--fs-xxs);
    user-select: none;
  }

  &__addFieldBtn {
    font-size: var(--fs-xxs);
    margin-top: 1.1rem;
    border: 1px solid #538270;
    padding: 1.2rem 3rem;
    background-color: transparent;
    border-radius: 1.4rem;
    cursor: pointer;
  }

  &__formContainer {
    margin-bottom: 2rem;
  }

  &__form {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $breakpoint-xs) {
      margin-bottom: 2rem;
    }

    .single-field {
      display: flex;
      border: 1px solid #e3e7eb;
      padding: 1rem;
      border-radius: 1.4rem;
      width: 100%;
      margin-top: 1rem;
      cursor: pointer;

      > div {
        width: 100%;
      }

      &__is-closed {
        display: none;
      }

      &__is-opened {
        display: block;
        border-top: 1px solid #e3e7eb;
        padding: 3rem;
        @media screen and (max-width: $breakpoint-xs) {
          padding: 0;
        }
      }

      &__checkbox {
        position: absolute;
        opacity: 0;
        z-index: 1;
        left: 0;
        width: 4rem;
        height: 2rem;

        & + svg {
          margin-right: 2rem;
          top: 1.2rem;
          height: fit-content;

          rect {
            fill: var(--clr-white);
            stroke: var(--bd-clr-primary);
          }

          path {
            display: none;
            stroke: var(--clr-primary);
          }
        }

        &:checked {
          & + svg {
            rect {
              fill: var(--clr-lightgreen);
            }
            path {
              display: block;
            }
          }
        }
      }

      &__delete {
        cursor: pointer;
        width: 3rem;
        margin: 1rem 1.5rem;
        height: fit-content;
        padding-right: 5px;
      }

      &__accordion {
        padding: 1.5rem;
        border: 1px solid #e3e7eb;
        border-radius: 1.4rem;
        width: 63rem;
        padding: 2rem 0;

        &-heading {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h2 {
            font-size: var(--fs-xs);
            font-weight: 600;
            color: var(--clr-black);
          }

          button {
            background: transparent;
            border: none;
            cursor: pointer;

            img {
              width: 1.5rem;
            }
          }
        }
      }
      &__heading-controls {
        display: flex;
        align-items: center;
        gap: 0.8rem;
      }
      &__delete {
        cursor: pointer;
      }
    }
  }

  &__description {
    color: var(--text-clr);
    opacity: 0.5;
    font-size: var(--fs-xs);
    padding: 3rem 4rem;
  }

  &__importButton {
    margin-top: auto;
    align-self: center;
    width: 30rem;
    border: none;
    border-radius: 10px;
    background: linear-gradient(
      to bottom,
      rgba(160, 186, 176, 1),
      rgba(66, 118, 98, 1)
    );
    color: white;
    height: 47px;
    font-size: var(--fs-xs);
    padding: 1rem;
  }
}

.farmFieldsModal {
  text-align: center;
  bottom: 1rem;
  padding: 1rem;
  bottom: 3rem;

  &__icon {
    height: 10vh;
  }

  &__heading {
    margin: 0;
    color: var(--text-clr);
    margin-top: 1.2rem;
    font-size: 2rem;
  }

  @media screen and (max-width: $breakpoint-xs) {
    &__icon {
      height: 10vh;
    }
    &__heading {
      font-size: 2rem;
      padding: 0 1rem;
    }
  }

  @media screen and (max-width: 30rem) {
    &__icon {
      height: 12vh;
    }
    &__heading {
      font-size: 2rem;
    }
  }
}

.modal__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;

  &__message {
    font-size: 1.2rem;
  }

  img {
    width: 6rem;
    height: 6rem;
  }

  &__actions {
    width: 100%;
    display: flex;
    gap: var(--spacing-sm);
  }
}

.success-modal {
  &-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    p {
      font-size: 1.3rem;
    }

    span{
      color: var(--clr-primary);
      font-weight: var(--fw-600);
    }

    img {
      width: 6rem;
      height: 6rem;
    }
  }
}
