@import "../_variables";

.setup-info {
  background-color: var(--clr-white);
  padding: 2rem;
  height: 100vh;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
  position: relative; 

  @media screen and (max-width:$breakpoint-xs) {
    border-bottom-left-radius: 0;
    border-top-right-radius: 16px;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-bottom: 2rem;
  }

  &__form {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;

    @media screen and (max-width: $breakpoint-lg) {
      display: block;
    }

    .form__input {
      margin: 0;

      &:nth-of-type(3) {
        grid-column: 1 / -1;
        width: 100%;

        textarea {
          width: 100%;
          height: 10rem;
        }
      }
    }
  }

  button {
    width: 30rem;
    position: absolute;
    left: 50%;
    bottom: 3rem;
    transform: translate(-50%, 0);
  }
}
