@import "../_variables";

.new-field {
  background-color: var(--clr-white);
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
  &__form {
    border: 1px solid #e3e7eb;
    padding: 2rem;
    border-radius: 1rem;
    color: #999999;
    text-transform: uppercase;

    select {
      background: transparent;
      border: 1px solid #e3e7eb;
      padding: 1.6rem;
      display: block;
      border-radius: 1rem;
      font-size: var(--fs-xxs);
      width: 30rem;
    }
    label {
      display: block;
      font-size: var(--fs-xxs);
    }
    &__FirstGroup,
    &__buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;

      @media screen and (max-width: $breakpoint-xs) {
        display: block;
      }
    }
    &__SecondGroup {
      display: flex;
      @media screen and (max-width: $breakpoint-lg) {
        display: block;
      }
      img {
        width: 40rem;
        padding-right: 2rem;
        @media screen and (max-width: $breakpoint-lg) {
          width: auto;
          margin: 2rem 0;
        }
      }
      div {
        width: 100%;

        span {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;
        }
      }
    }
    &__buttons {
      width: 100%;
      height: 100%;
      margin: 4rem auto;

      @media screen and (max-width: $breakpoint-lg) {
        margin: 3rem auto;
        display: flex;
      }

      button {
        @media screen and (max-width: $breakpoint-xs) {
          width: 30rem;
        }
      }
    }
  }
}

.form{
    border: 1px solid #e3e7eb;
    padding: 2rem;
    border-radius: 1rem;
    color: #999999;
    text-transform: uppercase;

    @media screen and (max-width: $breakpoint-xs) {
    padding: 1rem;
  }

  &__FirstGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;

    @media screen and (max-width: $breakpoint-xs) {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  }

  &__SecondGroup {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 2rem;
    margin-top: 2rem;

    /* 
     * If you have nested FormGroups (e.g., one for description, 
     * one for the two date fields), you can target them like:
     */
    .description-group {
      grid-column: 1 / -1; /* or span both columns on big screens, if you want */
    }

    .date-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    @media screen and (max-width: $breakpoint-xs) {
      grid-template-columns: 1fr; /* single column on mobile */
      
      .description-group {
        margin-bottom: 1rem;
      }

      .date-group {
        grid-template-columns: 1fr; /* each date field is its own row */
      }
    }
  }
}

.input-error{
  border: 2px solid #db2d16 !important;
}