@import "../../_variables";


.main {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: var(--clr-white);
  

  &__section {
    height: 100vh;
    display: grid;
    grid-template-columns: 34rem auto;


    @media screen and (max-width: $breakpoint-xs) {
      display: block;
    }

    &-sidebar {
      padding: 18rem 5rem;
      color: var(--clr-white);
      text-align: left;

      @media screen and (max-width:$breakpoint-xs) {
        text-align: center;
      }

      h1 {
        font-size: var(--fs-md);
        margin-top: 1rem;
      }

      p {
        font-size: var(--fs-xs);
        font-weight: 200;
        color:var(--clr-lightgray);
      }

      @media screen and (max-width: $breakpoint-xs) {
        padding: 2rem 5rem;
      }
    }

    &-content {
      height: 100vh;
      overflow-y: hidden;
    }

    &-starting {
      background-color: var(--clr-white);
      border-radius: 1rem;
      div {
        background-color: var(--clr-white);
        height: 100vh;
        text-align: center;
        align-content: center;

        @media screen and (max-width: $breakpoint-xs) {
          height:80vh;
          border-top-right-radius: 1rem;
        }
      }

      h2 {
        color: var(--clr-primary);
        font-size: var(--fs-md);
        font-weight: 300;
      }

      p {
        font-size: var(--fs-xs);
        padding: 2rem;
      }

      button {
        width: 33rem;
        margin: 0 auto;
      }
    }
  }
}

.success-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
  gap: var(--spacing-md);

  @media screen and (max-width: $breakpoint-xs) {
    margin-top: 12rem;
  }

  img {
    width: 5rem;
    height: 5rem;
    margin-top: -2.5rem;
  }

  .success-modal__title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333333;
    margin: 0;
  }

  .success-modal__message {
    font-size: var(--fs-xxs);
    color: #4a4a4a;
    font-weight: 400;

    span {
      font-weight: 600;
      color: rgba(13, 24, 20, 0.5);
    }
  }
}