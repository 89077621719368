@import "../../_variables";

.user-management {
  width: 100%;
  border: 1px solid var(--bd-clr-primary);
  border-radius: 1.6rem;
  padding: 3rem;

  @media screen and (max-width: $breakpoint-sm) {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0;
    border: none;
    background-color: white;
    height: 100%;
    width: 100%;
    flex-direction: column;
  }

  &__header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--bd-clr-primary);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
    color: var(--clr-primary);

    &__head {
      margin-left: auto;
      font-size: 2rem;
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      width: 4.4rem;
      height: 4.4rem;
      border: 1px solid var(--clr-lightgray);
      border-radius: 1rem;
      filter: grayscale(1);
    }
  }

  &__close-btn {
    display: flex;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background-color: var(--clr-lightgray);
    border: 0;
    border-radius: 1rem;
    padding: 1rem;
  }

  &__worker {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-xs);
    position: relative;

    @media screen and (max-width: $breakpoint-lg) {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      gap: var(--spacing-xs);
    }

    &__display {

      span {
        color: #999999;
        font-size: 1.3rem;
      }

      &__heading {
        color: #999999;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__add-user-btn {
          margin-left: auto;
          border: none;
          background-color: white;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: var(--spacing-xs);
          background-color: var(--clr-lightgray);
          color: var(--clr-primary);
          font-weight: 600;
          padding: 0.5rem 1rem;
          border-radius: 0.5rem;

          img {
            height: 2.2rem;
            width: 2.2rem;
          }
        }
      }

      &__user {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 50px;
        padding: 1rem;
        border-radius: 1rem;
        margin-top: 2rem;
        border: 1px solid #e3e7eb;

        &-info {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: var(--spacing-xs);
          font-size: 1rem;
        }

        h2 {
          margin-right: 1rem;
          color: var(--text-clr);
        }

        span {
          font-size: 1rem;
          color: #999999;
          padding-top: 0.5rem;
        }

        img {
          width: 24px;
          height: 24px;
          cursor: pointer;
          margin-left: auto;
        }
      }
    }

    &__info {
      display: flex;
      justify-content: flex-start;
      gap: var(--spacing-xs);
      align-items: center;
      margin-top: 3.5rem;
      padding: 2rem;
      height: 53px;
      background-color: #f2f3f2;
      border-radius: 1.6rem;
      padding: 2rem;

      h2 {
        color: #7b8794;
        font-size: small;
      }
    }
  }

  @media (max-width: $breakpoint-xs) {
    .user-management__worker {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
    }

    .user-management__worker__info {
      width: 100%;
      margin-top: 2rem;
      padding: 1.5rem;
    }
  }
}

.success-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
  gap: var(--spacing-md);

  img {
    width: 5rem;
    height: 5rem;
    margin-top: -2.5rem;
  }

  .success-modal__title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333333;
    margin: 0;
  }

  .success-modal__message {
    font-size: 1.2rem;
    color: #4a4a4a;
    font-weight: 400;

    span {
      font-weight: 600;
      color: var(--clr-primary);
    }
  }
}
