@import "../../_variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 1rem;
  text-align: center;
}

.header {
  color: var(--clr-black);
  font-weight: 400;
  font-size: var(--fs-xxs);
  margin-bottom: 10px;

  .checkIcon {
    font-size: var(--fs-xs);
  }
}

.subheader {
  color: var(--clr-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.subtext {
  color: var(--clr-primary);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
