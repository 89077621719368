.custom-select {
    select {
      padding: 1rem !important;
      width: fit-content;
      margin-left: auto;
    }
}

.view-filter{
    select{
        width: fit-content;
    }
}
