.rect {
  position: relative;
  width: 100%;
  background-color: var(--clr-lightgray);
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to right,
      transparent 0% 20%,
      var(--clr-lightgreen) 50% 70%,
      transparent 80% 100%
    );
    z-index: 1;
    animation-name: slide;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

  &--row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
