@import "./_variables";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

/* global */

:root {
  font-size: 62.5%;

  /* ----- Global Variables ----- */

  --site-width: 192rem;

  /* ----- Spacing Variables ----- */

  --spacing-xs: 1rem;
  --spacing-sm: 1.6rem;
  --spacing-md: 2.4rem;
  --spacing-lg: 5rem;

  /* ----- Section Variables ----- */

  --section-pd: var(--spacing-sm) var(--spacing-lg);

  /* ----- Color Variables ----- */

  --clr-primary: #427662;
  --clr-secondary: #a0bab0;
  --clr-lightgreen: #d9e4e0;
  --clr-lightgray: #f2f3f2;
  --clr-yellow: #ecb365;
  --clr-white: #ffffff;
  --clr-black: #000000;
  --clr-disabled: #f2f3f280;
  --clr-lightred: #fc8787;
  --clr-blue: #599eed;

  --text-clr: #0d1814;

  --bd-clr-primary: #e4e7e6;

  --alert-clr: #eb0b0b;

  /* ---- Calendar Priority Colors ---- */

  --priority-very-high: #db2d16;
  --priority-high: #fc8787;
  --priority-medium: #51b888;
  --priority-normal: #599eed;
  --priority-low: #7eaadc;
  --priority-default: #d9d9d9;

  /* ---- Breakpoints ----*/

  --breakpoint-sm: 768px;

  /* ----- Task Status ----- */

  --clr-completed: #51b888;
  --clr-in-progress: #51b888;
  --clr-overdue: #db2d16;
  --clr-upcoming: #599eed;

  /* ----- Typography Variables ----- */

  --ff: "DM Sans", system-ui;

  --fs-xxs: 1.4rem;
  --fs-xs: 1.6rem;
  --fs-sm: 2.2rem;
  --fs-md: 2.4rem;
  --fs-lg: 5rem;

  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  --lh-md: 1.5;

  --h1-mb: 0.25em;

  /* ----- Form Elements Variables ----- */

  --label-mb: 0.5em;

  --input-mb: 2rem;
  --input-pd: 0.75em;

  --button-mb: 0.75em;
  --button-pd: 0.85em;

  --form-gr-mb: 2.4rem;
  --form-el-br: 0.625em;
  --form-section-br: 1.6rem;
}

/* global */

* {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::selection {
  color: var(--clr-white);
  background-color: var(--clr-primary);
}

::-webkit-scrollbar {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

body {
  padding: 1.6rem;
  font-family: var(--ff);
}

.container {
  display: grid;
  grid-template-columns: 10rem repeat(11, 1fr);
  gap: var(--spacing-md);
  margin: 0 auto;
  width: 100%;
  max-width: var(--site-width);
  min-height: calc(100vh - 1.6rem * 2);

  @media screen and (max-width: $breakpoint-xs) {
    grid-template-columns: repeat(12, 1fr);
  }
}

main {
  grid-column: 2 / -1;

  @media screen and (max-width: $breakpoint-xs) {
    grid-column: span 12;
    padding-bottom: 15rem;
  }
}

/* map */

.leaflet-control-container {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
}

.leaflet-marker-tooltip {
  font-size: var(--fs-xxs) !important;
  line-height: 1.5 !important;
  font-weight: var(--fw-500) !important;
  color: var(--clr-white) !important;
  text-shadow: 0 1px 2px var(--text-clr) !important;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;

  &::before {
    display: none;
  }
}

.leaflet-control-attribution {
  font-size: 1rem;
}

/* heading */

h1 {
  margin-bottom: var(--h1-mb);
  font-size: var(--fs-lg);
  line-height: var(--lh-md);

  @media screen and (max-width: $breakpoint-xs) {
    font-size: var(--fs-md);
  }
}

.subtitle {
  display: inline-block;
  font-size: var(--fs-sm);
  line-height: var(--lh-md);

  @media screen and (max-width: $breakpoint-xs) {
    font-size: var(--fs-xs);
  }
}

/* form */

.form {
  &__group {
    margin-bottom: var(--form-gr-mb);

    &--title {
      text-align: center;
      font-size: 2.4rem;
    }

    &--description {
      font-size: 1.6rem;
      text-align: center;
      color: var(--text-clr);
      margin: 1.2rem 0 1rem 0;
    }
  }

  &__input {
    margin-bottom: var(--input-mb);

    &:last-child {
      margin-bottom: 0;
    }

    label {
      display: inline-block;
      margin-bottom: var(--label-mb);
      font-size: var(--fs-xs);
    }

    input,
    textarea {
      display: block;
      padding: var(--input-pd);
      width: 100%;
      font-size: var(--fs-xs);
      font-family: var(--ff);
      line-height: var(--lh-md);
      color: var(--text-clr);
      border: 1px solid var(--bd-clr-primary);
      border-radius: var(--form-el-br);
    }
  }

  &__button {
    display: block;
    margin-bottom: var(--button-mb);
    padding: var(--button-pd);
    width: 100%;
    font-size: var(--fs-xs);
    font-family: var(--ff);
    font-weight: var(--fw-700);
    color: var(--clr-white);
    background-color: var(--clr-white);
    border: 1px solid transparent;
    border-radius: var(--form-el-br);
    z-index: 1;
    cursor: pointer;
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    &:last-child {
      width: 35rem;
      margin: auto;

      @media screen and (min-height: $breakpoint-xs) {
        width: 35rem;
        margin: auto;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to top,
        var(--clr-primary),
        var(--clr-secondary)
      );
      border-radius: var(--form-el-br);
      z-index: -1;
      transition: opacity 0.2s ease-in-out;
      pointer-events: none;
    }

    &:hover {
      color: var(--text-clr);
      border-color: var(--clr-secondary);

      &::before {
        opacity: 0;
      }
    }

    &--transparent {
      color: var(--text-clr);
      background: none;
      border: 1px solid var(--clr-secondary);

      &::before {
        opacity: 0;
      }
    }
  }

  &__info {
    font-size: var(--fs-xxs);
    line-height: var(--lh-md);
    text-align: center;
    color: var(--text-clr);

    &-link {
      font-weight: var(--fw-700);
      color: var(--clr-primary);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &--underline {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
