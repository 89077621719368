@import "../../__variables.scss";

.calendar-filters {
  padding: var(--spacing-md) 0;
  padding-top: 0;

  &__field-label {
    @media screen and (max-width: $breakpoint-xs) {
      font-size: 0.9rem !important;
      padding-left: 2.5rem !important;

      img {
        left: 0.5rem !important;
      }
    }
  }

  &__field-icon {
    @media screen and (max-width: $breakpoint-xs) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__select {
    label {
      display: none;
    }

    @media screen and (max-width: $breakpoint-xs) {
      img {
        width: 1.4rem;
        height: 1.4rem;
      }

      select {
        width: fit-content;
        padding-right: 0;
        font-size: 0.9rem;
      }
    }
  }

  &__filtering {
    @media screen and (max-width: $breakpoint-xs) {
      button {
        width: fit-content !important;
        min-width: fit-content;

        span {
          font-size: 1rem;
        }

        img {
          width: 1.5rem;
          height: 1.5rem;
          margin-left: 1rem;
        }
      }
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    @media screen and (max-width: $breakpoint-sm) {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__group {
    flex: 1;
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 1rem !important;
    margin: 0 !important;

    @media screen and (max-width: $breakpoint-xs) {
      gap: 0.5rem !important;
    }

    &:last-child {
      flex: 0;

      @media screen and (max-width: $breakpoint-xs) {
        flex: 1 !important;
      }
    }

    @media screen and (max-width: $breakpoint-xs) {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    h3 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 1rem;
      gap: 1rem;
      font-size: 1.1rem;
      font-weight: var(--fw-500);
      line-height: 1.5;
      text-transform: uppercase;
      color: var(--text-clr);

      &.calendar-filters__view {
        @media screen and (max-width: $breakpoint-xs) {
          display: none;
        }
      }

      &__filtering {
        &__title {
          font-size: 1rem;
        }
      }
    }

    > * {
      flex: initial;
    }
  }

  &__select {
    label {
      display: none !important;
      width: fit-content;
      margin-bottom: 10rem;
    }
  }
}
